.App {
  width: 100vw;
  height: 100vh;

}

body {
  margin: 0px;
  padding: 0;
}

h1 {
  padding: 0;
  /* margin-top: 0; */
}

.row {
  display: flex;
  justify-content: center;
  /* center child elements horizontally */
  align-items: center;
  /* center child elements vertically */
}

.row>.col {
  margin-bottom: 20px;
  /* add some space between rows */
  width: 30vw;
}

.dashboard-container {
  /* background-color: pink; */
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

a {
  text-decoration: none !important;
  color: white !important;
  display: block !important;
  /* width: 100%;
  height: 100%; */
  /* margin-top: 10px; */
  /* justify-content: center; */
}

a:visited {
  text-decoration: none;
}

.sidebar {
  /* top: 0; */
  height: 100vh !important;
  width: 250px !important;
  /* background-color: #644e5b; */
  /* background-color: #cfaaf0; */
  background-color: #1d3557 !important;
  display: flex !important;
  position: fixed !important;
  border-radius: 0px 15px 15px 0px !important;
  /* box-shadow: 3px 5px 5px #45A29E; */
  border-right: 3px solid #a8dadc !important;
}

.sidebarlist {
  height: auto !important;
  width: 100% !important;
  padding: 0 !important;
  margin-top: 10px !important;
}

.sidebarlist .row {
  width: 100% !important;
  height: 50px !important;
  /* border: 1px solid white; */
  list-style-type: none !important;
  margin: 0% !important;
  display: flex !important;
  flex-direction: row !important;
  color: #a8dadc !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* text-transform: uppercase; */
}

.logout {
  color: white;
  width: 255px;
  height: 50px;
  /* border: 1px solid white; */
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: #45A29E;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  /* left: 10px; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding: 0;
}

.logout button {
  background-color: #1d3557;
  color: white;
  border-color: #a8dadc;
  border-style: solid;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.logout button:active {
  background-color: #e63946;
  color: white;
  box-shadow: inset 0 3px 5px #0b0C10;
  border-color: black;
}

a:hover {
  text-decoration: none;
  /* color: black; */
}

.sidebarlist .row:hover {
  cursor: pointer;
  /* background-color: #293846; */
  /* background-color: #ec7171; */
  background-color: #e63946;
  color: white !important;

}

.sidebarlist .row:active {
  box-shadow: inset 5px 0px 5px #0b0C10;
}

.sidebarlist #active {
  /* background-color: #293846; */
}

.row #icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

.row #title {
  flex: 70%;
}

.header {
  background-color: #293846;
  color: white;
  height: 3vh;
  /* width: 250px; */
  /* position: absolute; */
  /* display: flex; */
  text-align: center;
  font-size: large;
}

.home {
  display: flex;
  min-height: 96vh;
  display: flex;
  margin-left: 255px;
  /* background: pink; */
  width: 82vw;
  padding: 0.5em;
  /* margin: 25vh auto 0; */

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
}

.homeItem {
  /* background: lightblue; */
  margin: 0.5em;
  width: 100%;
  text-align: center;
}

.database {
  display: flex;
  margin-left: 255px;
}

.myaccount {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 255px;
  width: 85vw;
  justify-content: center;
}

.my-account-header {
  width: 80vw;
  text-align: center;
}

.my-account-body {
  width: 80vw;
  text-align: center;
}

.trialsContainer {
  display: flex;
  margin-left: 255px;
  /* background: pink; */
  height: 98%;
  min-width: 75vw;
  padding: 0.5em;
  /* margin: 25vh auto 0; */

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;
}

.trials-container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  height: 50px;
  width: 80vw;
}

.form-container {
  width: 50vw;
  text-align: center;
  /* background-color: pink; */
  justify-content: center;
}

.create-trials {
  display: flex;
  margin-left: 255px;
  /* background: aliceblue; */
  height: 98%;
  width: 85vw;
  padding: 0.5em;
  /* margin: 25vh auto 0; */

  display: flex;
  text-align: center;
  justify-content: center;
}

.trialsCard {
  background: lightblue;
  margin: 0.5em;
  width: 99%;
  border-radius: 10px;
}

.mapped-trials {
  width: 81vw;
}

.trialPage {
  margin-left: 255px;
  background-color: pink;
  width: 85vw;
  font-family: 'Courier New', Courier, monospace;
}

.component-header {
  background-color: aqua;
  width: 85vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.component-title {
  background-color: aliceblue;
  width: 42vw;
  margin: 2px;
}

.edit-button-div {
  background-color: aliceblue;
  width: 40vw;
  margin: 2px;
  text-align: right;
}

.edit-button-div button {
  background-color: #0b0C10;
  color: white;
  border-color: #0b0C10;
  border-style: solid;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.edit-button-div button:active {
  background-color: #0b0C10;
  color: white;
  box-shadow: inset 2px 2px 5px #45A29E;
}

.trial-titles {
  width: 80vw;
  margin: 5px;
  text-align: center;
  height: 10vh;
  /* margin-top: 10px; */
}

.main-trial-info {
  /* background-color: aqua; */
  margin: 0.5em;
  border-radius: 10px;
  /* min-height: ; */
  display: flex;
}

.main-trial-left {
  width: 50%;
  height: 100%;
  margin-right: 10px;
}

.main-trial-right {
  width: 50%;
}

.conditions-trial-info {
  /* background-color: aqua; */
  margin: 0.5em;
  border-radius: 10px;
}

.patients-trial-info {
  /* background-color: aqua; */
  margin: 0.5em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 10px;
  justify-content: center;
}

.box-patients-trial-info {
  border: #0b0C10;
  margin: 5px;
  background-color: aliceblue;
  width: 15vw;
  text-align: center;
  border-radius: 10px;
}

.arm-groups-trial-info {
  /* background-color: aqua; */
  margin: 0.5em;
  border-radius: 10px;
}

.locations-trial-info {
  margin: 0.5em;
  border-radius: 10px;
}

.location {
  background-color: aliceblue;
  border-radius: 5px;
}

.patientsContainer {
  margin-left: 255px;
  /* background: pink; */
  height: 100%;
  width: 81vw;
  padding: 0.5em;
  /* margin: 25vh auto 0; */

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;
}

.patients-container-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(236, 233, 233);
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(173, 173, 173);
  border-width: 1px;
}

.patients-container-header button {
  border-radius: 5px;
  background-color: #e37f87;
  border-color: #e37f87;
  color: white;
  margin-right: 20px;
}

.patientsButtons button {
  height: 30px;
  width: 150px;
  border-radius: 5px;
  margin-right: 20px;
}

.patients-container-header h1 {
  margin-left: 20px;
}

.pagination button {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  margin-right: 20px;
}

.patientsCard {
  /* background: lightblue; */
  margin: 0.5em;
  width: 35vw;
  height: 30%;
  border-radius: 10px;
}

.patientCardStorage {
  /* background-color: aquamarine; */
  minHeight: 75vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
}

.patientPage {
  margin-left: 255px;
  width: 80vw;
}

.patient-info {
  display: flex;
  margin: 10px;
  width: 80vw;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.patient-general-info {
  width: 25vw;
  justify-content: center;
}

.patient-trial-info {
  width: 25vw;
}

.patient-condition-info {
  width: 25vw;
  text-decoration: none;
  text-align: center;
}

.patient-condition-info li {
  list-style-type: none;
}

patient-document-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mapped-documents a {
  color: blue !important;
}


.patientsButtons {
  height: 10vh;
  /* background-color: aqua; */
  justify-content: center;
  text-align: center;
}

.patient-chart-div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#measurement-form {
  /* text-align: center; */
}

.dashboard {
  margin-left: 255px;
  background: pink;
  height: 97vh;
  width: 82vw;
  padding: 0.5em;
  /* margin: 25vh auto 0; */

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
}

.chart-div {
  height: 25vh;
  align-items: center;
  justify-content: center;
}

.login {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 1);
}

.login::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('clinical-laboratory.jpg');
  background-size: cover;
  opacity: 0.5;
}


.login-title {
  text-align: center;
  opacity: 1;
}

.login h1 {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10px;
}

.login button {
  border-radius: 5px;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5px;
}

.login input {
  margin-left: 5px;
}

#login-form {
  text-align: center;
}

#login-form-container {
  background-color: rgba(0, 0, 0, .4);
  height: 125px;
  width: 500px;
  border-radius: 20px;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  justify-content: center;
}

.notesContainer {
  margin-left: 255px;
  height: 100vh;
  width: 85vw;
  padding: 0.5em;
  /* margin: 25vh auto 0; */
  /* background-color: pink; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
  justify-content: center;
  margin-top: 0;
  vertical-align: top !important;
}

.noteCardStorage {
  /* background-color: aliceblue; */
  min-height: 75vh;
  width: 84vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
  /* margin: auto; */
  vertical-align: top;
  margin-top: 10px;
}

.notes-container-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(236, 233, 233);
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(173, 173, 173);
  border-width: 1px;
  vertical-align: top !important;
  margin-top: 0;
  height: 40px;
  width: 50%;
}

.notes-container-header input {
  margin-left: 10px;
  width: 600px;
}

#trial-delete-button {
  margin-top: 10px;
  width: 100px;
}